import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Space,
    Typography,
} from 'antd';
import { Row } from 'antd/lib';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Layout, { Content } from 'antd/lib/layout/layout';
import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
    getMultiFactorResolver,
    MultiFactorError,
    MultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    PhoneMultiFactorInfo,
    RecaptchaVerifier,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { auth } from '../util/firebase';

import login from '../assets/login.jpg';
import logo from '../favicon.png';

type LoginFormValues = {
    email: string;
    password: string;
    remember: boolean;
};

const { Text } = Typography;

function Login() {
    const [loading, setLoading] = useState(false);
    const [mfa, setMfa] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mfaError, setMfaError] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [resolver, setResolver] = useState<MultiFactorResolver | null>(null);
    const [recaptchaVerifier, setRecaptchaVerifier] =
        useState<RecaptchaVerifier | null>(null);

    useEffect(() => {
        console.log('Initializing recaptcha verifier...');
        setRecaptchaVerifier(
            new RecaptchaVerifier(
                'user-login-button',
                {
                    size: 'invisible',
                },
                auth
            )
        );
    }, []);

    const onFinish = useCallback(
        ({ email, password }: LoginFormValues) => {
            if (!email) {
                message.error('Sähköposti vaaditaan');
                return;
            }

            setLoading(true);
            console.log('Signing in with email and password...');
            let loginPromise = signInWithEmailAndPassword(
                auth,
                email,
                password
            );

            loginPromise
                .catch(async (error: FirebaseError) => {
                    if (
                        error.code === 'auth/multi-factor-auth-required' ||
                        error.code === 'auth/missing-multi-factor-info'
                    ) {
                        const multiFactorResolver = getMultiFactorResolver(
                            auth,
                            error as MultiFactorError
                        );
                        setResolver(multiFactorResolver);
                        // Presume only one MFA option has been enrolled.
                        const hint = multiFactorResolver
                            ?.hints[0] as PhoneMultiFactorInfo;

                        const phoneInfoOptions = {
                            multiFactorHint: hint,
                            session: multiFactorResolver.session,
                        };

                        const phoneAuthProvider = new PhoneAuthProvider(auth);

                        if (!recaptchaVerifier) {
                            throw new FirebaseError(
                                'recaptcha-not-initialized',
                                'Recaptcha verifier not initialized'
                            );
                        }

                        return phoneAuthProvider
                            .verifyPhoneNumber(
                                phoneInfoOptions,
                                recaptchaVerifier
                            )
                            .then((id: string) => {
                                console.log('Verification ID', id);
                                setMfa(true);
                                setPhoneNumber(hint.phoneNumber);
                                setVerificationId(id);
                            });
                    } else {
                        setLoading(false);
                        message.error('Kirjautuminen epäonnistui');
                        throw error;
                    }
                })
                .catch((error: FirebaseError) => {
                    if (
                        error.code === 'auth/cancelled-popup-request' ||
                        error.code === 'auth/popup-closed-by-user'
                    ) {
                        return;
                    }

                    console.error(error);
                })
                .then((userCredential) => {
                    if (userCredential) {
                        localStorage.setItem(
                            'lastLogin',
                            dayjs(
                                userCredential.user.metadata.lastSignInTime
                            ).toISOString()
                        );
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [loading, recaptchaVerifier]
    );
    const handleMfaVerification = async ({ mfaCode }: { mfaCode: string }) => {
        console.log('Verifying MFA code...');
        setLoading(true);
        const cred = PhoneAuthProvider.credential(verificationId, mfaCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        try {
            if (!resolver) {
                throw new Error('Resolver not initialized');
            }

            return await resolver.resolveSignIn(multiFactorAssertion);
        } catch (error) {
            console.error(error);
            setMfaError(true);
        } finally {
            setLoading(false);
        }

        return null;
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{ display: 'flex' }}>
                <Row style={{ width: '100%' }}>
                    <Col
                        xs={24}
                        md={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                    >
                        <img
                            src={login}
                            alt="fabric-ai-login"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '20px',
                            }}
                        />
                        <Space
                            style={{
                                zIndex: 3,
                                position: 'absolute',
                                top: 40,
                                left: 40,
                            }}
                        >
                            <img src={logo} alt="Logo" style={{ width: 40 }} />{' '}
                            <Text
                                style={{
                                    color: 'black',
                                    fontSize: 24,
                                    fontWeight: 'bolder',
                                }}
                            >
                                FabricAI
                            </Text>
                        </Space>
                    </Col>
                    <Col
                        xs={24}
                        md={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                        }}
                    >
                        <Card
                            bordered={false}
                            title={
                                mfa
                                    ? 'Kaksivaiheinen tunnistautuminen'
                                    : 'Kirjaudu sisään'
                            }
                            style={{
                                minWidth: '400px',
                                width: '650px',
                            }}
                        >
                            {!mfa ? (
                                <Form
                                    name="basic"
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 32 }}
                                    initialValues={{ remember: true }}
                                    onFinishFailed={() => {}}
                                    autoComplete="off"
                                    layout="vertical"
                                    onFinish={onFinish}
                                >
                                    <Form.Item label="Sähköposti" name="email">
                                        <Input type="email" required />
                                    </Form.Item>
                                    <Form.Item label="Salasana" name="password">
                                        <Input.Password required />
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item
                                            noStyle
                                            name="remember"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Muista minut</Checkbox>
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            loading={loading}
                                            style={{
                                                width: '100%',
                                                fontSize: 16,
                                                backgroundColor: 'black',
                                                padding: '25px',
                                            }}
                                            type="primary"
                                            htmlType="submit"
                                            id="user-login-button"
                                        >
                                            Kirjaudu sisään
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ) : (
                                <Form
                                    name="mfa"
                                    layout="vertical"
                                    onFinish={handleMfaVerification}
                                >
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            marginBottom: 16,
                                        }}
                                    >
                                        <Text>
                                            Vahvistuskoodi on lähetetty numeroon{' '}
                                            {phoneNumber}
                                        </Text>
                                    </Space>
                                    <Form.Item
                                        name="mfaCode"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Input
                                            size="large"
                                            status={
                                                mfaError ? 'error' : undefined
                                            }
                                        />
                                    </Form.Item>
                                    {mfaError && (
                                        <Text type="danger">
                                            Koodi on virheellinen
                                        </Text>
                                    )}

                                    <Button
                                        loading={loading}
                                        style={{ width: '100%' }}
                                        type="primary"
                                        htmlType="submit"
                                        id="mfa-verify-button"
                                    >
                                        Vahvista koodi
                                    </Button>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default Login;

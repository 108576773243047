import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import {
    Alert,
    Badge,
    Button,
    Descriptions,
    DescriptionsProps,
    Divider,
    Input,
    List,
    message,
    Popconfirm,
    Popover,
    Rate,
    Select,
    Space,
    Spin,
    Statistic,
    Tabs,
    TabsProps,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from 'react-query';
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { no404Retry, notifyError } from '../api';
import { getTeams, updateTeam } from '../api/teams';
import {
    deleteUser,
    getUserByUID,
    requestPasswordReset,
    updateUser,
} from '../api/users';
import AddToTeam from '../components/AddToTeam';
import BackLink from '../components/BackLink';
import Header from '../components/Header';
import Identifier from '../components/Identifier';
import TeamList from '../components/TeamList';
import { Team, User, UserClient } from '../interfaces';
import { roles } from '../util/constants';
import { renderDateTime } from '../util/datetime';
import ErrorPage from './ErrorPage';
import TagEditor from '../components/TagEditor';

const UserDetails = () => {
    const uid = useParams().id!!;
    const {
        data: user,
        isLoading,
        isError,
        error,
    } = useQuery(['user', uid], () => getUserByUID(uid), {
        retry: no404Retry,
    });

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { data: teams } = useQuery('teams', () =>
        getTeams().then((c) => c.items)
    );

    const userTeamMutation: UseMutationResult<Team, AxiosError, Team> =
        useMutation(
            (team: Team) => {
                let members = team.members;
                if (members?.find((member) => member.uid === uid)) {
                    members = members.filter((member) => member.uid !== uid);
                } else {
                    members?.push({ ...user!, role: 'member' });
                }
                return updateTeam(team.id, { members });
            },
            {
                onSuccess: (team) => {
                    queryClient.setQueryData<User>(['user', uid], (user) => ({
                        ...user!,
                        teams: team.members.find((member) => member.uid === uid)
                            ? [...(user?.teams || []), team.id]
                            : user?.teams?.filter(
                                  (teamId) => teamId !== team.id
                              ),
                    }));
                    queryClient.invalidateQueries(['user', uid]);
                    queryClient.invalidateQueries('teams');
                },
                onError: notifyError,
            }
        );

    const userMutation = useMutation(
        (user: Partial<User>) => updateUser(uid, user),
        {
            onMutate: (updated) => {
                queryClient.setQueryData<User>(['user', uid], {
                    ...user!,
                    ...updated,
                });
            },
            onSuccess: () => {
                message.success('Käyttäjän tiedot päivitetty');
            },

            onError: notifyError,
        }
    );

    const deleteUserMutation = useMutation(() => deleteUser(uid), {
        onSuccess: (uid) => {
            queryClient.invalidateQueries(['user', uid]);
            message.info('Käyttäjä poistettu');
            navigate('/users');
        },
        onError: notifyError,
    });

    const passwordResetMutation = useMutation(
        (user: User) => requestPasswordReset(user.email),
        {
            onSuccess: () => {
                message.info('Salasanan palautuspyyntö lähetetty');
            },
            onError: notifyError,
        }
    );

    const [searchParams, setSearchParams] = useSearchParams();

    const onFavoriteChange = (clientId: number, add: boolean) => {
        if (add) {
            userMutation.mutate({
                ...user!,
                favorites: [...(user?.favorites || []), clientId],
            });
        } else {
            userMutation.mutate({
                ...user!,
                favorites: user?.favorites?.filter(
                    (favorite) => favorite !== clientId
                ),
            });
        }
    };

    const [nameEditorState, setNameEditorState] = useState({
        visible: false,
        name: user?.name,
    });
    const [emailEditorState, setEmailEditorState] = useState({
        visible: false,
        email: user?.email,
    });

    if (isLoading) return <Spin size="large" />;

    if (isError)
        return (
            <ErrorPage
                title={`Virhe haettaessa käyttäjää ${uid}`}
                error={error}
                backTo="users"
            />
        );

    const role = roles.find(
        (role) => (user?.role || 'ORGANIZATION_USER') === role.value
    );

    const changeUserRole = (value: string) => {
        userMutation.mutate(
            { role: value },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['user', uid]);
                    message.success('Rooli päivitetty');
                },
                onError: notifyError,
            }
        );
    };

    const tabsItems: TabsProps['items'] = [
        {
            key: 'teams',
            label: 'Tiimit',
            children: (
                <>
                    <TeamList
                        teams={user?.teams
                            ?.map(
                                (teamId) =>
                                    teams?.find((team) => team.id === teamId)!
                            )
                            .filter((team) => team)}
                        onRemove={userTeamMutation.mutate}
                        removingTeam={
                            (userTeamMutation.isLoading &&
                                userTeamMutation.variables?.id) ||
                            undefined
                        }
                    />
                    <AddToTeam
                        style={{ marginTop: 32 }}
                        organizationId={user?.organizationId || undefined}
                        onSubmit={(team) => userTeamMutation.mutate(team)}
                        submitting={userTeamMutation.isLoading}
                        blacklist={user?.teams}
                    />
                    {userTeamMutation.isError && (
                        <Alert
                            type="error"
                            message={userTeamMutation.error.message}
                        />
                    )}
                </>
            ),
        },
        {
            key: 'clients',
            label: 'Asiakkaat',
            children: (
                <List
                    style={{ width: 400, maxHeight: 600, overflow: 'auto' }}
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={user?.clientDetails || undefined}
                    renderItem={(client) => (
                        <ClientListItem
                            key={client.id}
                            client={client}
                            user={user!}
                            onFavoriteChange={onFavoriteChange}
                        />
                    )}
                />
            ),
        },
    ];

    const descriptionItems: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Sähköposti',
            children: (
                <Space>
                    {emailEditorState.visible ? (
                        <>
                            <Input
                                style={{ width: 250 }}
                                size="small"
                                value={emailEditorState.email}
                                onChange={(e) =>
                                    setEmailEditorState({
                                        visible: true,
                                        email: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="text"
                                size="small"
                                onClick={() =>
                                    userMutation.mutate(
                                        {
                                            email: emailEditorState.email,
                                        },
                                        {
                                            onSuccess: () => {
                                                setEmailEditorState({
                                                    visible: false,
                                                    email: '',
                                                });
                                                message.success(
                                                    'Sähköposti päivitetty'
                                                );
                                            },
                                        }
                                    )
                                }
                                icon={<CheckOutlined />}
                            />
                        </>
                    ) : (
                        <>
                            {user?.email}
                            <Button
                                size="small"
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() =>
                                    setEmailEditorState({
                                        visible: !emailEditorState.visible,
                                        email: user?.email,
                                    })
                                }
                            />
                        </>
                    )}
                </Space>
            ),
        },
        {
            key: '2',
            label: 'Organisaatio',
            children: user?.organization?.name || '-',
        },
        {
            key: '3',
            label: 'Rooli',
            children:
                role?.value === 'SYSTEM_ADMIN' ? (
                    <Badge color={role.color} text={role.label} />
                ) : (
                    <Select
                        onChange={changeUserRole}
                        style={{ width: 220 }}
                        placeholder="Valitse rooli"
                        value={role?.value}
                    >
                        {roles.slice(0, 5).map((roleOption) => (
                            <Select.Option
                                key={roleOption.value}
                                value={roleOption.value}
                            >
                                <Badge
                                    color={roleOption.color}
                                    text={roleOption.label}
                                />
                            </Select.Option>
                        ))}
                    </Select>
                ),
        },
        {
            key: '4',
            label: 'UID',
            children: <Identifier>{user?.uid}</Identifier>,
        },
        {
            key: '5',
            label: 'Luotu',
            children: renderDateTime(user?.createdAt),
        },
        {
            key: '6',
            label: 'Päivitetty',
            children: renderDateTime(user?.updatedAt),
        },
        {
            key: '7',
            label: 'Tagit',
            children: (
                <TagEditor
                    tags={Object.entries(user?.tags || {}).map(
                        ([key, value]) => `${key}=${value}`
                    )}
                    onChange={(tags) =>
                        userMutation.mutate({
                            tags: tags.reduce(
                                (acc, cur) => ({
                                    ...acc,
                                    [cur.split('=')[0]!]: cur.split('=')[1],
                                }),
                                {}
                            ),
                        })
                    }
                    validate={(tag: string) => tag.split('=').length === 2}
                    loading={
                        userMutation.isLoading && !!userMutation.variables?.tags
                    }
                    inputStyle={{ width: 200 }}
                />
            ),
        },
    ];

    return (
        <Content>
            <Header resolver={() => user?.name} />
            <Space size="middle" align="center" style={{ marginBottom: 24 }}>
                <BackLink to="/users" />
                <Title style={{ margin: 0 }} level={2}>
                    {user?.name}
                </Title>
                <Popover
                    title="Muokkaa käyttäjän nimeä"
                    trigger="click"
                    open={nameEditorState.visible}
                    onOpenChange={(visible) =>
                        setNameEditorState({ visible, name: user!.name })
                    }
                    placement="bottom"
                    content={
                        <>
                            <Input
                                disabled={userMutation.isLoading}
                                value={nameEditorState.name}
                                onChange={(e) =>
                                    setNameEditorState({
                                        visible: true,
                                        name: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                loading={userMutation.isLoading}
                                onClick={() =>
                                    userMutation.mutate(
                                        { name: nameEditorState.name },
                                        {
                                            onSuccess: () => {
                                                setNameEditorState({
                                                    visible: false,
                                                    name: '',
                                                });
                                                message.success(
                                                    'Käyttäjän nimi päivitetty'
                                                );
                                            },
                                        }
                                    )
                                }
                                style={{ marginTop: 8 }}
                            >
                                Tallenna
                            </Button>
                        </>
                    }
                >
                    <Button type="text" icon={<EditOutlined />}></Button>
                </Popover>
                <Divider type="vertical" />
                <Popconfirm
                    title="Oletko varma?"
                    onConfirm={() => deleteUserMutation.mutate()}
                >
                    <Button loading={deleteUserMutation.isLoading} danger>
                        Poista käyttäjä
                    </Button>
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm
                    title="Oletko varma?"
                    onConfirm={() => passwordResetMutation.mutate(user!)}
                >
                    <Button
                        disabled={!user}
                        loading={passwordResetMutation.isLoading}
                    >
                        Palauta salasana
                    </Button>
                </Popconfirm>
            </Space>
            <Space style={{ width: '100%' }} size="large">
                <Descriptions
                    size="middle"
                    bordered
                    column={2}
                    items={descriptionItems}
                />
                <Statistic
                    title="Laskuja käsitelty"
                    value={user?.completedInvoices}
                />
            </Space>
            <Tabs
                defaultActiveKey={searchParams.get('tab') || undefined}
                style={{ marginTop: 32 }}
                onChange={(key) => {
                    searchParams.set('tab', key);
                    setSearchParams(searchParams);
                }}
                items={tabsItems}
            />
        </Content>
    );
};

export default UserDetails;

const ClientListItem = ({
    client,
    user,
    onFavoriteChange,
}: {
    client: UserClient;
    user: User;
    onFavoriteChange: (clientId: number, add: boolean) => any;
}) => {
    return (
        <List.Item>
            <List.Item.Meta
                description={
                    <Link to={`/clients/${client?.id}`}>
                        <Text strong>{client?.name}</Text>
                    </Link>
                }
            />

            <Rate
                count={1}
                value={user?.favorites?.includes(client?.id) ? 1 : 0}
                onChange={(value) => onFavoriteChange(client?.id!, value === 1)}
            />
        </List.Item>
    );
};
